<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>纸飞机</p>
      <p>童书馆</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          宁波纸飞机童书馆（纸飞机儿童阅读推广中心以下简称纸飞机）是一家用商业所得养公益梦想的个性化儿童阅读推广服务组织。第一家纸飞机成立于2012年6月，并在宁波市海曙区民政局备案成立，参与社会公益服务活动，2017年正式注册民办非企业机构。创始人陈菁菁成立书馆的初衷：让阅读成为跟刷牙和洗脸一样自然的习惯。书馆取名“纸飞机”的寓意：希望阅读能跟童年的纸飞机一样，无门槛、无阶级，每个孩子都可以拥有。纸飞机在本着这个初衷，自成立以来便一直提供馆内免费阅读环境；风雨无阻的每日免费传统晚间故事会。2020年疫情来临时，线下实体都没法开门的时候，书馆的阅读老师们及时改变阅读推广方式，在线上举办了700多场故事会，为医护人员的子女提供了主题课程和特色陪伴，也为宅家不能出门的孩子们提供了内容丰富的故事会。
        </p>
        <p>现在，纸飞机的公益阅读推广模式已相当成熟：</p>
        <p>1、每天晚上风雨无阻举办免费故事会；</p>
        <p>2、每周末阅读主题活动；每个月举办一场妈妈读书会；</p>
        <p>3、每季度有一场亲子阅读公益讲座；</p>
        <p>4、每半年会请专家做大型公益讲座；</p>
        <p>5、不定期进行义卖、捐赠书籍等活动。</p>
        <p>
          纸飞机童书馆先后被中国国家图书馆学会评选为全国十佳绘本馆、全国亲子阅读体验基地，2022年荣获海曙区3A社会组织等荣誉称号。创始人陈菁菁被评为全国十佳最具影响力的绘本馆馆主、全国最美家庭等十余项荣誉。
        </p>
      </div>

      <div class="main-video">
        <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="./audio/introducePageSex_bg.mp3"
        ></audio>
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSex_show_one.jpg" al t="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSex_show_two.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSex_show_three.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageSex_show_four.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("audios").play();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;
  .section-aside {
    position: absolute;
    top: 160px;
    left: 72px;
    white-space: nowrap;
    // text-align: center;
    // transform: translateX(-50%);
    font-size: 72px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    p:last-child {
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      font-size: 28px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      text-indent: 2em;
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 40px 22px 40px;
      text-align: center;
      height: 100px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 94px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
  }
}
</style>
